<template>
  <div class="container">
    <div class="information">


      <!-- 1. 品牌型号 -->
      <van-field v-model="model" label="1.品牌型号" type="textarea" placeholder="请输入品牌型号" rows="1" autosize/>

      <!-- 2. 车架后6位 -->
      <van-field v-model="vin" label="2.车架后6位" type="text" placeholder="请输入车架后6位" :maxlength="6"/>

      <!-- 3. 出厂年月 -->
      <van-field v-model="productionDateStr"
                 label="3.出厂年月" placeholder="请选择出厂年月" readonly
                 @click="showProductionDatePicker = true"/>
      <van-popup v-model="showProductionDatePicker" position="bottom">
        <van-datetime-picker
            v-model="productionDate"
            type="year-month"
            title="选择出厂年月"
            @confirm="onConfirmProductionDate"
            @cancel="showProductionDatePicker = false"
        />
      </van-popup>

      <!-- 4. 上牌年月 -->
      <van-field v-model="registrationDateStr" label="4.上牌年月" placeholder="请选择上牌年月" readonly
                 @click="showRegistrationDatePicker = true"/>
      <van-popup v-model="showRegistrationDatePicker" position="bottom">
        <van-datetime-picker
            v-model="registrationDate"
            type="year-month"
            title="选择上牌年月"
            @confirm="onConfirmRegistrationDate"
            @cancel="showRegistrationDatePicker = false"
        />
      </van-popup>

      <!-- 5. 公里数 -->
      <van-field v-model="mileage" label="5.公里数/万公里" type="number" placeholder="请输入公里数"/>

      <!-- 6. 指导价 -->
      <van-field v-model="price" label="6.指导价/万元" type="number" placeholder="请输入指导价"/>

      <!-- 7. 车辆户籍地 -->
      <van-field v-model="location" label="7.车辆户籍地" placeholder="请输入车辆户籍地"/>

      <!-- 8. 过户次数 -->
      <van-field name="stepper" label="8.过户次数">
        <template #input>
          <van-stepper v-model="transfers" min="0"/>
        </template>
      </van-field>

      <!-- 9. 车况描述 -->
      <van-field v-model="condition" label="9.车况描述" type="textarea" placeholder="请输入车况描述" rows="1" autosize/>

      <!-- 10. 钥匙数量 -->
      <van-field name="stepper" label="10.钥匙数量">
        <template #input>
          <van-stepper v-model="keys" min="0"/>
        </template>
      </van-field>

      <!-- 11. 交强险截止年月 -->
      <van-field v-model="insuranceStr" label="11.交强险截止年月" placeholder="请选择交强险截止年月" readonly
                 @click="showInsuranceDatePicker = true"/>
      <van-popup v-model="showInsuranceDatePicker" position="bottom">
        <van-datetime-picker
            v-model="insurance"
            type="year-month"
            title="选择交强险截止年月"
            @confirm="onConfirmInsuranceDate"
            @cancel="showInsuranceDatePicker = false"
        />
      </van-popup>

      <!-- 12. 是否按揭 -->
      <van-field name="radio" label="12.是否按揭">
        <template #input>
          <van-radio-group v-model="loan" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <!-- 13. 预计大本到店日期 -->
      <van-field v-model="docDateStr" label="13.预计大本到店日期" placeholder="请选择预计大本到店日期" readonly
                 @click="showDocDatePicker = true"/>
      <van-popup v-model="showDocDatePicker" position="bottom">
        <van-datetime-picker
            v-model="docDate"
            type="date"
            title="选择预计大本到店日期"
            @confirm="onConfirmDocDate"
            @cancel="docArrived"
        >
          <template #cancel>
            <span style="color: #2ae058;">已到店</span>
          </template>
        </van-datetime-picker>
      </van-popup>

      <!-- 14. 预计车辆到店日期 -->
      <van-field v-model="arrivalDateStr" label="14.预计车辆到店日期" placeholder="请选择预计车辆到店日期" readonly
                 @click="showArrivalDatePicker = true"/>
      <van-popup v-model="showArrivalDatePicker" position="bottom">
        <van-datetime-picker
            v-model="arrivalDate"
            type="date"
            title="选择预计车辆到店日期"
            @confirm="onConfirmArrivalDate"
            @cancel="carArrived"
        >

          <template #cancel>
            <span style="color: #2ae058;">已到店</span>
          </template>
        </van-datetime-picker>
      </van-popup>

      <!-- 15. 备注 -->
      <van-field v-model="notes" label="15.备注" type="textarea" placeholder="请输入备注" rows="1" autosize/>

    </div>


    <div class="button-box">
      <van-button class="btn" type="info" round block @click="copyText">复制文本</van-button>
      <van-button class="btn" type="primary" round block @click="clearText">清除文本</van-button>
    </div>


    <!-- 展示点击复制后的文本效果 -->
    <!--    <div v-if="copiedText" class="copied-text">{{ copiedText }}</div>-->
  </div>
</template>

<script>

import {Toast} from "vant";

export default {
  name: "DaFengChe",
  data() {
    return {
      model: "",
      vin: "",
      productionDate: new Date(),
      productionDateStr: "",
      registrationDate: new Date(),
      registrationDateStr: "",
      mileage: "",
      price: "",
      location: "",
      transfers: 0,
      condition: "",
      keys: 0,
      insurance: new Date(),
      insuranceStr: "",
      loan: "",
      docDate: new Date(),
      docDateStr: "",
      arrivalDate: new Date(),
      arrivalDateStr: "",
      notes: "",
      showProductionDatePicker: false,
      showRegistrationDatePicker: false,
      showInsuranceDatePicker: false,
      showDocDatePicker: false,
      showArrivalDatePicker: false,
      copiedText: ""
    };
  },
  methods: {
    formatDate(date, length) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');  // 月份从0开始，需加1
      const day = String(date.getDate()).padStart(2, '0');         // 补0到两位数
      if (length === 2) {
        return `${year}/${month}`;
      } else if (length === 3) {
        return `${year}/${month}/${day}`;
      } else {
        return '';
      }
    },
    docArrived() {
      this.docDateStr = "已到店";
      this.showDocDatePicker = false;
    },
    carArrived() {
      this.arrivalDateStr = "已到店";
      this.showArrivalDatePicker = false;
    },
    onConfirmProductionDate() {
      this.productionDateStr = this.formatDate(this.productionDate, 2);
      this.showProductionDatePicker = false;
      this.saveData();
    },
    onConfirmRegistrationDate() {
      this.registrationDateStr = this.formatDate(this.registrationDate, 2);
      this.showRegistrationDatePicker = false;
      this.saveData();
    },
    onConfirmInsuranceDate() {
      this.insuranceStr = this.formatDate(this.insurance, 2);
      this.showInsuranceDatePicker = false;
      this.saveData();
    },
    onConfirmDocDate() {
      this.docDateStr = this.formatDate(this.docDate, 3);
      this.showDocDatePicker = false;
      this.saveData();
    },
    onConfirmArrivalDate() {
      this.arrivalDateStr = this.formatDate(this.arrivalDate, 3);
      this.showArrivalDatePicker = false;
      this.saveData();
    },
    copyText() {
      const textToCopy = [
        `1. 品牌型号: ${this.model}`,
        `2. 车架后6位: ${this.vin}`,
        `3. 出厂年月: ${this.productionDateStr}`,
        `4. 上牌年月: ${this.registrationDateStr}`,
        `5. 公里数/万公里: ${this.mileage}`,
        `6. 指导价/万元: ${this.price}`,
        `7. 车辆户籍地: ${this.location}`,
        `8. 过户次数: ${this.transfers}`,
        `9. 车况描述: ${this.condition}`,
        `10. 钥匙数量: ${this.keys}`,
        `11. 交强险截止年月: ${this.insuranceStr}`,
        `12. 是否按揭: ${this.loan}`,
        `13. 预计大本到店日期: ${this.docDateStr}`,
        `14. 预计车辆到店日期: ${this.arrivalDateStr}`,
        `15. 备注: ${this.notes}`
      ].join('\n');

      // 使用 textarea 复制内容到剪贴板
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      Toast.success('文本已成功复制到剪贴板');
      console.log("文本已成功复制到剪贴板");
      this.copiedText = textToCopy;
    },
    clearText() {
      this.model = "";
      this.vin = "";
      this.productionDate = new Date();
      this.productionDateStr = "";
      this.registrationDate = new Date();
      this.registrationDateStr = "";
      this.mileage = "";
      this.price = "";
      this.location = "";
      this.transfers = 0;
      this.condition = "";
      this.keys = 0;
      this.insurance = new Date();
      this.insuranceStr = "";
      this.loan = "";
      this.docDate = new Date();
      this.docDateStr = "";
      this.arrivalDate = new Date();
      this.arrivalDateStr = "";
      this.notes = "";
      this.copiedText = "";
      this.saveData();
    },
    saveData() {
      localStorage.setItem('daFengData', JSON.stringify(this.$data));
    },
    loadData() {
      const savedData = localStorage.getItem('daFengData');
      let savedDataJSON = JSON.parse(savedData);
      if (savedData) {
        Object.assign(this.$data, savedDataJSON);

        //将字符串转换为日期对象
        this.productionDate = new Date(savedDataJSON.productionDate);
        this.registrationDate = new Date(savedDataJSON.registrationDate);
        this.insurance = new Date(savedDataJSON.insurance);
        this.docDate = new Date(savedDataJSON.docDate);
        this.arrivalDate = new Date(savedDataJSON.arrivalDate);
      }


    },
  },


  created() {
    this.loadData();


    this.showProductionDatePicker = false;
    this.showRegistrationDatePicker = false;
    this.showInsuranceDatePicker = false;
    this.showDocDatePicker = false;
    this.showArrivalDatePicker = false;

  },
  watch: {
    model: {handler: 'saveData'},
    vin: {handler: 'saveData'},
    productionDate: {handler: 'saveData'},
    productionDateStr: {handler: 'saveData'},
    registrationDate: {handler: 'saveData'},
    registrationDateStr: {handler: 'saveData'},
    mileage: {handler: 'saveData'},
    price: {handler: 'saveData'},
    location: {handler: 'saveData'},
    transfers: {handler: 'saveData'},
    condition: {handler: 'saveData'},
    keys: {handler: 'saveData'},
    insurance: {handler: 'saveData'},
    insuranceStr: {handler: 'saveData'},
    loan: {handler: 'saveData'},
    docDate: {handler: 'saveData'},
    docDateStr: {handler: 'saveData'},
    arrivalDate: {handler: 'saveData'},
    arrivalDateStr: {handler: 'saveData'},
    notes: {handler: 'saveData'}
  }
};
</script>


<style scoped>

.container {
  padding: 10px;
}

.btn {
  margin-bottom: 10px;
}

.button-box {
  margin: 20px;
}

</style>
