<template>
  <div class="container">
    <div class="information">

      <!-- 1.预计车辆到店日期 -->
      <van-field v-model="arrivalDateStr" label="1.预计车辆到店日期" placeholder="请选择预计车辆到店日期" readonly
                 @click="showArrivalDatePicker = true"/>
      <van-popup v-model="showArrivalDatePicker" position="bottom">
        <van-datetime-picker
            v-model="arrivalDate"
            type="date"
            title="选择预计车辆到店日期"
            @confirm="onConfirmArrivalDate"
            @cancel="carArrived"
        >
          <template #cancel>
            <span style="color: #2ae058;">已到店</span>
          </template>
        </van-datetime-picker>
      </van-popup>

      <!-- 2.预计大本到店日期 -->
      <van-field v-model="docDateStr" label="2.预计大本到店日期" placeholder="请选择预计大本到店日期" readonly
                 @click="showDocDatePicker = true"/>
      <van-popup v-model="showDocDatePicker" position="bottom">
        <van-datetime-picker
            v-model="docDate"
            type="date"
            title="选择预计大本到店日期"
            @confirm="onConfirmDocDate"
            @cancel="docArrived"
        >
          <template #cancel>
            <span style="color: #2ae058;">已到店</span>
          </template>
        </van-datetime-picker>
      </van-popup>

      <!-- 3.出口渠道 -->
      <van-field v-model="exportWay" label="3.出口渠道" placeholder="请选择出口渠道" readonly
                 @click="showExportPicker = true"/>
      <van-popup v-model="showExportPicker" position="bottom">
        <van-picker
            :columns="exportOptions"
            title="选择出口渠道"
            show-toolbar
            @confirm="onConfirmExportWay"
            @cancel="showExportPicker = false"
        />
      </van-popup>
      <!-- 4.过户方式-->
      <van-field v-model="transferWay" label="4.过户方式" placeholder="请选择过户方式" readonly
                 @click="showTransferPicker = true"/>
      <van-popup v-model="showTransferPicker" position="bottom">
        <van-picker
            :columns="transferOptions"
            title="选择过户方式"
            show-toolbar
            @confirm="onConfirmTransferWay"
            @cancel="showTransferPicker = false"
        />
      </van-popup>

      <!-- 5.备注 -->
      <van-field v-model="otherNotice" label="5.备注" type="textarea" placeholder="请输入备注" rows="1"
                 autosize/>

    </div>

    <div class="button-box">
      <van-button class="btn" type="info" block round @click="copyText">复制文本</van-button>
      <van-button class="btn" type="primary" block round @click="clearText">清除文本</van-button>
    </div>

  </div>
</template>

<script>
import {Toast} from 'vant';

export default {
  name: "CheShangJi",
  data() {
    return {
      arrivalDate: new Date(),
      arrivalDateStr: "",
      docDate: new Date(),
      docDateStr: "",
      exportWay: "",
      transferWay: "",
      exportOptions: ['马利克', '王启麟', '未定'],
      transferOptions: ['成都中转','直过待出口'],
      otherNotice: "",
      copiedText: "",
      showArrivalDatePicker: false,
      showDocDatePicker: false,
      showExportPicker: false,
      showTransferPicker: false,
    };
  },
  methods: {
    formatDate(date, length) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      if (length === 2) {
        return `${year}/${month}`;
      } else if (length === 3) {
        return `${year}/${month}/${day}`;
      } else {
        return '';
      }
    },
    carArrived() {
      this.arrivalDateStr = "已到店";
      this.showArrivalDatePicker = false;
    },
    docArrived() {
      this.docDateStr = "已到店";
      this.showDocDatePicker = false;
    },
    onConfirmArrivalDate() {
      this.arrivalDateStr = this.formatDate(this.arrivalDate, 3);
      this.showArrivalDatePicker = false;
    },
    onConfirmDocDate() {
      this.docDateStr = this.formatDate(this.docDate, 3);
      this.showDocDatePicker = false;
    },
    onConfirmExportWay(picker) {
      this.exportWay = picker;
      this.showExportPicker = false;
    },
    onConfirmTransferWay(picker) {
      this.transferWay = picker;
      this.showTransferPicker = false;
    },
    copyText() {
      const textToCopy = [
        `1. 预计车辆到店日期: ${this.arrivalDateStr}`,
        `2. 预计大本到店日期: ${this.docDateStr}`,
        `3. 出口渠道: ${this.exportWay}`,
        `4. 过户方式: ${this.transferWay}`,
        `5. 备注: ${this.otherNotice}`
      ].join('\n');

      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      Toast.success('文本已成功复制到剪贴板');
      this.copiedText = textToCopy;
    },
    clearText() {
      this.arrivalDate = new Date();
      this.arrivalDateStr = "";
      this.docDate = new Date();
      this.docDateStr = "";
      this.exportWay = "";
      this.transferWay = "";
      this.otherNotice = "";
      this.copiedText = "";
    },
    saveData() {
      localStorage.setItem('cheShangData', JSON.stringify(this.$data));
    },
    loadData() {
      const savedData = localStorage.getItem('cheShangData');
      if (savedData) {
        let savedDataJSON = JSON.parse(savedData);
        Object.assign(this.$data, savedDataJSON);

        // 将日期字符串转换为日期对象
        this.arrivalDate = new Date(savedDataJSON.arrivalDate);
        this.docDate = new Date(savedDataJSON.docDate);
      }
    },

  },

  created() {
    this.loadData();

    this.showArrivalDatePicker = false;
    this.showDocDatePicker = false;
    this.showExportPicker = false;
    this.showTransferPicker = false;
  },
  watch: {
    arrivalDate: {handler: 'saveData'},
    arrivalDateStr: {handler: 'saveData'},
    docDate: {handler: 'saveData'},
    docDateStr: {handler: 'saveData'},
    exportWay: {handler: 'saveData'},
    transferWay: {handler: 'saveData'},
    repairProject: {handler: 'saveData'},
    addProject: {handler: 'saveData'},
    otherNotice: {handler: 'saveData'},
  }
};
</script>

<style scoped>
.container {
  padding: 10px;
}

.btn {
  margin-bottom: 10px;
}

.button-box {
  margin: 20px;
}
</style>